import "./style.css";
import { FaPencilAlt } from "react-icons/fa";
import { getInputDate, formatDateAndTime } from "../../utils/date.utils";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { environment } from "../../constants";
import { DashboardCalenderView } from "../../components/Graph";
import { JournalCardNew } from "../../components/JournalCardNew";

const DashboardApp = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const [selectedDate, setSelectedDate] = useState(new Date());

  const today = new Date();
  const offsetMinutes = today.getTimezoneOffset();

  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [index, setIndex] = useState(2);

  const host = environment.host;

  useEffect(() => {
    axios
      .get(
        `${host}/mobile/journal?offset=${offsetMinutes}&date=${getInputDate(
          today
        )}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setItems(res.data.data);
        setTotal(res.data.count);
      })
      .catch((err) => console.log(err));
  }, []);

  const fetchMoreData = (date) => {
    axios
      .get(
        `${host}/mobile/journal?offset=${offsetMinutes}&date=${getInputDate(
          date ?? selectedDate
        )}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setItems(res.data.data);
        setTotal(res.data.count);
      })
      .catch((err) => console.log(err));

    setIndex((prevIndex) => prevIndex + 1);
  };

  const handleDateChange = (date) => {
    console.log(date);
    setSelectedDate(date);
    fetchMoreData(date);
  };

  const createJournal = () => {
    console.log("Floating button clicked");
  };

  return (
    <div>
      <DashboardCalenderView onDateChange={handleDateChange} />
      <div className="journal-header">
        {items && items.length > 0 ? (
          items.map((item) => (
            <JournalCardNew
              key={item.id}
              title={formatDateAndTime(new Date(item?.savedAt))}
              mood={item?.reflection?.mood}
              description={item?.reflection?.summary}
              icons={item?.reflection?.keywords ?? []}
              emoji={item?.reflection?.emoji}
              item={item}
            />
          ))
        ) : (
          <div className="no-content">No content animation will come here</div>
        )}
      </div>
    </div>
  );
};

export default DashboardApp;
