import { environment } from "../constants";
import axios from "axios";

export const AuthService = {
  _host: environment.host,
  _user_key: "user",
  _token_key: "token",
  whoAmI: async () => {
    const token = AuthService.getToken();
    return new Promise((resolve, reject) => {
      axios
        .get(environment.host + "/mobile/whoami", {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          AuthService.setUser(response.data.user);
          resolve(response.data.user);
        })
        .catch((error) => {
          console.error(error);
          AuthService.logout();
          reject(error);
        });
    });
  },
  login: async (code) => {
    return new Promise((resolve, reject) => {
      axios
        .post(environment.host + "/mobile/login", {
          code: code,
        })
        .then(async (response) => {
          if (response.data.token) {
            AuthService.setToken(response.data.token);
            const res = await AuthService.whoAmI();
            resolve(res);
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  getUser: () => {
    const user = localStorage.getItem(AuthService._user_key);
    try {
      if (user && typeof user === "object") {
        return JSON.parse(user);
      } else {
        throw new Error("Invalid or no user");
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  setUser: (user) => {
    localStorage.setItem(AuthService._user_key, JSON.stringify(user));
  },
  setToken: (token) => {
    localStorage.setItem(AuthService._token_key, token);
  },
  getToken: () => {
    return localStorage.getItem(AuthService._token_key);
  },
  logout: async () => {
    localStorage.clear();
  },
};
