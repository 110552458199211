import React, { useState, useEffect } from "react";
import axios from "axios";
import "./style.css";
import { ApiRequests } from "../../services/api-service";
import JournalCard from "../JournalDetail";
import { format } from "date-fns";
import { AiOutlineClockCircle } from "react-icons/ai";

const Clock = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  return <div className="clock">{format(time, "hh:mm:ss a")}</div>;
};

const CreateJournal = () => {
  const [content, setContent] = useState("");
  const [journalId, setJournalId] = useState(null);
  const [buttonLabel, setButtonLabel] = useState("Start Journal");
  const [placeHolderLabel, setplaceHolderLabel] = useState(
    "Hey! I'm here to listen to your thoughts and help you process them. The best part? Everything stays between you and me, and only you have access to your thoughts. Let's work together to make sense of what's on your mind and find clarity"
  );
  const [chat, setChat] = useState([]);
  const [reflection, setReflection] = useState({});

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleContentChange = (event) => {
    setContent(event.target.value);
  };

  const handleAddClick = async () => {
    setButtonLabel("Add to Journal");
    if (content.trim() !== "") {
      try {
        const userMessage = { role: "You", content: content };
        if (journalId === null) {
          const createResponse = await ApiRequests.startJournal();
          const id = createResponse.data.id;
          localStorage.setItem("journalId", id);
          setJournalId(id);
          const addResponse = await ApiRequests.addToJournal(id, content);
          setplaceHolderLabel(addResponse?.data?.response);
          setChat([
            { role: "Assistant", content: placeHolderLabel },
            userMessage,
            ...chat,
          ]);
        } else {
          const addResponse = await ApiRequests.addToJournal(
            journalId,
            content
          );
          localStorage.setItem("journalId", journalId);
          setplaceHolderLabel(addResponse?.data?.response);
          setChat([, userMessage, ...chat]);
        }
        setChat([
          { role: "Assistant", content: placeHolderLabel },
          userMessage,
          ...chat,
        ]);
        setContent("");
      } catch (error) {
        console.error("Error adding journal entry:", error);
      }
    }
    setContent("");
  };

  const handleReflect = async () => {
    const response = await ApiRequests.reflectJournal(journalId);
    const item = response.data;
    setReflection(item.response);
    setJournalId(null);
    setplaceHolderLabel(
      "Hey! I'm here to listen to your thoughts and help you process them. The best part? Everything stays between you and me, and only you have access to your thoughts. Let's work together to make sense of what's on your mind and find clarity"
    );
    setButtonLabel("Start Journal");
    setJournalId(null);
    setChat([]);
    setTimeout(() => {
      togglePopup();
    }, 100);
  };

  useEffect(() => {
    const id = localStorage.getItem("journalId");
    if (id) {
      setChat(JSON.parse(localStorage.getItem("journal")));
      setplaceHolderLabel(localStorage.getItem("placeholder"));
      setButtonLabel("Add to Journal");
      setJournalId(id);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("journal", JSON.stringify(chat));
    localStorage.setItem("placeholder", placeHolderLabel);
  }, [chat]);

  useEffect(() => {
    if (journalId) {
      localStorage.setItem("journalId", journalId);
    } else {
      localStorage.removeItem("journalId");
      localStorage.removeItem("journal");
      localStorage.removeItem("placeholder");
    }
  }, [journalId]);

  const handleCancel = () => {
    setJournalId(null);
    setJournalId(null);
    setplaceHolderLabel(
      "Hey! I'm here to listen to your thoughts and help you process them. The best part? Everything stays between you and me, and only you have access to your thoughts. Let's work together to make sense of what's on your mind and find clarity"
    );
    setButtonLabel("Start Journal");
    setJournalId(null);
    setChat([]);
    localStorage.removeItem("journalId");
    localStorage.removeItem("journal");
    localStorage.removeItem("placeholder");
  };

  return (
    <div>
      <div className="header">
        <AiOutlineClockCircle className="clock-icon" />
        <Clock />
      </div>
      <div className="create-journal">
        <textarea
          rows={15}
          value={content}
          onChange={handleContentChange}
          className="journal-textarea"
          placeholder={placeHolderLabel}
        ></textarea>
        <div className="button-container">
          {journalId != null && (
            <>
              <button className="add-button" onClick={handleCancel}>
                Cancel
              </button>
              <button className="add-button" onClick={handleReflect}>
                Save & Reflect
              </button>
            </>
          )}
          <button className="add-button" onClick={handleAddClick}>
            {buttonLabel}
          </button>
        </div>

        <div className="chat">
          {chat.map((entry, index) => (
            <div
              key={index}
              className={`chat-entry ${
                entry.role === "You" ? "You" : "Assistant"
              }`}
            >
              <p>{entry.content}</p>
            </div>
          ))}
        </div>
      </div>

      {isPopupOpen && (
        <div className="popup" onClick={togglePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={togglePopup}>
              &times;
            </span>
            <JournalCard item={reflection} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateJournal;
