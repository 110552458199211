import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./style.css";
import Lottie from "lottie-react";
import SplashLogin from "../../assets/animations/splash-login.json";

const Login = () => {
  return (
    <div className="login-container">
      <motion.h1
        className="login-title"
        animate={{ opacity: [0, 1], y: [-20, 0] }}
        transition={{ duration: 1 }}
      >
        Be well with AI
      </motion.h1>
      <div className="splash-logo">
        <Lottie animationData={SplashLogin} />
      </div>

      <motion.a
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        className="login-button"
        href="https://wa.me/919315856662?text=Login"
        target="_blank"
      >
        Login with WhatsApp
      </motion.a>
    </div>
  );
};

export default Login;
