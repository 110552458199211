import DashboardApp from "./Screen/Dashboard";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useSearchParams,
} from "react-router-dom";
import "./styles.css";
import { useState, useEffect } from "react";
import Login from "./Screen/Login";
import { AuthService } from "./services/auth-service";
import Dashboard from "./Screen/Dashboard/index";
import FooterNav from "./components/FooterNav/index";
import Analytics from "./Screen/Analytics/index";
import { ChatWindow } from "./Screen/Chat";
import Profile from "./components/Profile";
import CreateJournal from "./Screen/Journal";

export default function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  async function handleLogin() {
    const token = AuthService.getToken();
    try {
      if (token) {
        await AuthService.whoAmI(); // Corrected the typo here
        setIsAuthenticated(true);
        return;
      }
      throw new Error("no token");
    } catch (e) {
      setIsAuthenticated(false);
      await handleCode();
    }
  }

  async function handleCode() {
    if (code) {
      await AuthService.login(code);
      setIsAuthenticated(true);
    }
  }

  useEffect(() => {
    handleLogin();
  }, []);

  return (
    <>
      {isAuthenticated ? (
        <div>
          <div className="main">
            <Routes>
              <Route path="/" element={<DashboardApp />} />
              <Route path="/journal-list" element={<DashboardApp />} />
              <Route path="/dashboard" element={<Analytics />} />
              <Route path="/create" element={<CreateJournal />} />
              <Route path="/ai-chat" element={<ChatWindow />} />
              <Route path="/user" element={<Profile />} />
            </Routes>
          </div>
          <FooterNav />
        </div>
      ) : (
        <div>
          <Login />
        </div>
      )}
    </>
  );
}
