import React, { useEffect, useState } from "react";
import "./Profile.css"; // Make sure to create and link the CSS file
import { formatDateAndTime } from "../../utils/date.utils";

const Profile = () => {
  const [user, setUser] = useState({});

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUser(user);
  }, []);

  return (
    <div className="profile-card">
      <div className="profile-header">
        <div className="profile-avatar">{/* Placeholder for avatar */}</div>
        <div className="edit-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L8.5 20.5H5v-3.5l11.732-11.732z"
            />
          </svg>
        </div>
      </div>
      <div className="profile-info">
        <h3>{user.name}</h3>
        <p className="username">{user.mobile}</p>
        <p className="joined-date">
          Joined At:
          {formatDateAndTime(new Date(user.createdAt))}
        </p>
        <p className="friends-count">
          Metrics are in development. But you get the gist. Right?
        </p>
      </div>
      <div className="statistics">
        <div className="stat">
          <div className="stat-icon">🔥</div>
          <p className="stat-number">199</p>
          <p className="stat-label">Day streak</p>
        </div>
        <div className="stat">
          <div className="stat-icon">⚡</div>
          <p className="stat-number">7774</p>
          <p className="stat-label">Total XP</p>
        </div>
        <div className="stat">
          <div className="stat-icon">🏆</div>
          <p className="stat-number">25</p>
          <p className="stat-label">Achievements</p>
        </div>
        <div className="stat">
          <div className="stat-icon">📚</div>
          <p className="stat-number">43</p>
          <p className="stat-label">Courses</p>
        </div>
        <div className="stat">
          <div className="stat-icon">💬</div>
          <p className="stat-number">128</p>
          <p className="stat-label">Messages</p>
        </div>
        <div className="stat">
          <div className="stat-icon">✨</div>
          <p className="stat-number">5</p>
          <p className="stat-label">Badges</p>
        </div>
      </div>
    </div>
  );
};

export default Profile;
