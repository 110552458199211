export function formatDate(date) {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = days[date.getDay()];
  const dateNum = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day}, ${dateNum} ${month} ${year}`;
}

export function formatDateAndTime(date) {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = days[date.getDay()];
  const dateNum = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  return `${day}, ${dateNum} ${month} ${year}, ${hours}:${minutes} ${ampm}`;
}

export const getInputDate = (data) => {
  const month = (data.getMonth() + 1).toString();
  const date = data.getDate().toString();
  const year = data.getFullYear().toString();
  return [
    year,
    month.length > 1 ? month : "0" + month,
    date.length > 1 ? date : "0" + date,
  ].join("-");
};

export const getStartAndEndOfWeek = (date) => {
  const start = new Date(date);
  const end = new Date(date);

  // Adjust the start to the nearest previous Sunday
  start.setDate(start.getDate() - start.getDay());

  // Adjust the end to the nearest coming Saturday
  end.setDate(start.getDate() + 6);

  return { start, end };
};

export const generateWeekDates = (start) => {
  const dates = [];
  const currentDate = new Date(start);

  for (let i = 0; i < 7; i++) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

export function getWeekNumber(date) {
  const startOfYear = new Date(date.getFullYear(), 0, 1);
  const daysSinceStartOfYear = Math.floor(
    (date - startOfYear) / (24 * 60 * 60 * 1000)
  );
  const startDayOfWeek = startOfYear.getDay();
  const adjustedDayOfYear =
    daysSinceStartOfYear +
    (startDayOfWeek <= 3 ? startDayOfWeek : startDayOfWeek - 7);
  const weekNumber = Math.floor(adjustedDayOfYear / 7) + 1;
  return weekNumber;
}

export function getDayOfYear(date) {
  const startOfYear = new Date(date.getFullYear(), 0, 1);
  const daysSinceStartOfYear =
    Math.floor((date - startOfYear) / (24 * 60 * 60 * 1000)) + 1;
  return daysSinceStartOfYear;
}
