import { environment } from "../constants";
import axios from "axios";
import { AuthService } from "./auth-service";

export const ApiRequests = {
  host: environment.host,
  getHeaders: () => {
    return {
      Authorization: `Bearer ${AuthService.getToken()}`,
    };
  },
  startJournal: async () => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          environment.host + "/mobile/journal/new",
          {},
          {
            headers: {
              ...ApiRequests.getHeaders(),
            },
          }
        )
        .then(async (response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  addToJournal: async (id, content) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          environment.host + "/mobile/journal/add",
          {
            journalId: id,
            userMessage: content,
          },
          {
            headers: {
              ...ApiRequests.getHeaders(),
            },
          }
        )
        .then(async (response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  reflectJournal: async (id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          environment.host + "/mobile/journal/reflect",
          {
            journalId: id,
          },
          {
            headers: {
              ...ApiRequests.getHeaders(),
            },
          }
        )
        .then(async (response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  refreshSummary: async (date, offset) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          environment.host + "/mobile/journal/summary/refresh",
          { date, offset },
          {
            headers: {
              ...ApiRequests.getHeaders(),
            },
          }
        )
        .then(async (response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  getDailySummary: async (day, year) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          environment.host +
            `/mobile/journal/summary/daily?day=${day}&year=${year}`,
          {
            headers: {
              ...ApiRequests.getHeaders(),
            },
          }
        )
        .then(async (response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  getWeeklySummary: async (week, year) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          environment.host +
            `/mobile/journal/summary/weekly?week=${week}&year=${year}`,
          {
            headers: {
              ...ApiRequests.getHeaders(),
            },
          }
        )
        .then(async (response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  getMonthlySummary: async (month, year) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          environment.host +
            `/mobile/journal/summary/monthly?month=${month}&year=${year}`,
          {
            headers: {
              ...ApiRequests.getHeaders(),
            },
          }
        )
        .then(async (response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  getAllSummary: async (day, week, month, year) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          environment.host +
            `/mobile/journal/summary/all?day=${day}&week=${week}&month=${month}&year=${year}`,
          {
            headers: {
              ...ApiRequests.getHeaders(),
            },
          }
        )
        .then(async (response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  startChat: async (agentId, force) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          environment.host + `/mobile/chat/start`,
          {
            agentId,
            force,
          },
          {
            headers: {
              ...ApiRequests.getHeaders(),
            },
          }
        )
        .then(async (response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  continueChat: async (agentId, message) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          environment.host + `/mobile/chat/continue`,
          {
            agentId,
            message,
          },
          {
            headers: {
              ...ApiRequests.getHeaders(),
            },
          }
        )
        .then(async (response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  endChat: async (agentId, chatId, message) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          environment.host + `/mobile/chat/end`,
          {
            agentId,
            chatId,
          },
          {
            headers: {
              ...ApiRequests.getHeaders(),
            },
          }
        )
        .then(async (response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
};
