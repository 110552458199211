import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import {
  generateWeekDates,
  formatDate,
  getStartAndEndOfWeek,
} from "../../utils/date.utils";

const DashboardCalenderView = ({ onDateChange }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [calendarOpen, setCalendarOpen] = useState(false);
  const datePickerRef = useRef(null);
  const { start } = getStartAndEndOfWeek(new Date());
  const [weekDates, setWeekDates] = useState(generateWeekDates(start));

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setCalendarOpen(false);
    if (onDateChange) {
      const { start } = getStartAndEndOfWeek(date);
      setWeekDates(generateWeekDates(start));
      onDateChange(date);
    }
  };

  const handleCalendarClick = () => {
    setCalendarOpen(true);
  };

  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target)
    ) {
      setCalendarOpen(false);
    }
  };

  useEffect(() => {
    if (calendarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [calendarOpen]);

  return (
    <div className="dashboard-header">
      <div className="month-selector">
        <span></span>
        <span>{months[selectedDate.getMonth()]}</span>
        <span></span>
      </div>
      <div className="date-container" onClick={handleCalendarClick}>
        {weekDates.map((date, index) => (
          <div className="day" key={index}>
            <div className="day-name">
              {date.toLocaleString("en-US", { weekday: "short" })}
            </div>
            <div
              className={`date ${
                formatDate(selectedDate) === formatDate(date) ? "selected" : ""
              }`}
            >
              {date.getDate()}
            </div>
          </div>
        ))}
      </div>

      {calendarOpen && (
        <div className="overlay">
          <div className="date-picker-container" ref={datePickerRef}>
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              inline
            />
          </div>
        </div>
      )}
    </div>
  );
};

export { DashboardCalenderView };
