import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './Footer.css'; // Importing the CSS file
import JournalIcon  from "../../assets/footer/journal.png";
import DashboardIcon  from "../../assets/footer/journal.png";
import CreateIcon  from "../../assets/footer/journal.png";
import AIChatIcon  from "../../assets/footer/journal.png";
import UserIcon  from "../../assets/footer/journal.png";
const FooterNav = () => {
  return (
    <footer className="footer">
      <NavLink exact to="/journal-list" className="footer-item" activeClassName="active">
        <img src={JournalIcon} alt="Journal List" className="footer-icon" />
        <span className="footer-text">Journal</span>
      </NavLink>

      <NavLink to="/dashboard" className="footer-item" activeClassName="active">
        <img src={DashboardIcon} alt="Dashboard" className="footer-icon" />
        <span className="footer-text">Dashboard</span>
      </NavLink>

      <div className="center-icon-wrapper">
        <NavLink to="/create" className="center-icon">
          <img src={CreateIcon} alt="Create Journal" className="center-icon-img" />
        </NavLink>
      </div>

      <NavLink to="/ai-chat" className="footer-item" activeClassName="active">
        <img src={AIChatIcon} alt="AI Chat" className="footer-icon" />
        <span className="footer-text">AI Chat</span>
      </NavLink>

      <NavLink to="/user" className="footer-item" activeClassName="active">
        <img src={UserIcon} alt="User" className="footer-icon" />
        <span className="footer-text">User</span>
      </NavLink>
    </footer>
  );
};

export default FooterNav;