import React, { useState } from "react";
import "./index.css";
import JournalCard from "../../Screen/JournalDetail";

export const JournalCardNew = ({
  title,
  mood,
  description,
  icons,
  emoji,
  item,
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <>
      <div className="card" onClick={togglePopup}>
        <div className="card-header">
          <h3>{title}</h3>
        </div>
        <h4>{emoji}</h4>
        <p>{description}</p>
        <div className="icons">
          {icons.map((icon, index) => (
            <span key={index} className="icon">
              #{icon}{" "}
            </span>
          ))}
        </div>
      </div>

      {isPopupOpen && (
        <div className="popup" onClick={togglePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={togglePopup}>
              &times;
            </span>
            <JournalCard item={item} />
          </div>
        </div>
      )}
    </>
  );
};
