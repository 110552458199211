import { formatDateAndTime } from "../../utils/date.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSmile,
  faBook,
  faChartLine,
  faComments,
  faTasks,
  faThumbsUp,
  faThumbsDown,
} from "@fortawesome/free-solid-svg-icons";
import Button from "@mui/material/Button";
import "./style.css";
import { useNavigate } from "react-router-dom";

const JournalCard = ({ item }) => {
  return (
    <div className="journal-card">
      {item?.savedAt ? (
        <p className="small">{formatDateAndTime(new Date(item.savedAt))}</p>
      ) : (
        <></>
      )}
      <Reflection item={item} />
      <JournalEntry item={item} />
    </div>
  );
};

const Reflection = ({ item }) => {
  if (Object.keys(item?.reflection).length > 1) {
    return (
      <div className="reflection-entry">
        <hr />
        <h4>Reflection</h4>
        <p className="keywords reflection-box">
          {item?.reflection?.keywords.map((x) => `#${x}`).join(", ")}
        </p>

        <div className="reflection-box">
          <h4 className="mood-icon">
            <FontAwesomeIcon icon={faSmile} /> Mood
          </h4>
          <p className="mood">
            {item?.reflection?.emoji ?? item?.reflection?.mood}
          </p>
        </div>

        <div className="reflection-box">
          <h4 className="summary-icon">
            <FontAwesomeIcon icon={faBook} />
            Summary
          </h4>
          <p>{item?.reflection?.summary ?? "None"}</p>
        </div>

        <div className="reflection-box">
          <h4 className="analysis-icon">
            <FontAwesomeIcon icon={faChartLine} />
            Analysis
          </h4>
          <p>{item?.reflection?.analysis ?? "None"}</p>
        </div>

        <div className="reflection-box">
          <h4 className="feedback-icon">
            <FontAwesomeIcon icon={faComments} />
            Feedback
          </h4>
          <p>{item?.reflection?.feedback ?? "None"}</p>
        </div>

        <div className="reflection-box">
          <h4 className="action-icon">
            <FontAwesomeIcon icon={faTasks} />
            Action Items
          </h4>
          <p>{item?.reflection?.actionItems ?? "None"}</p>
        </div>

        <div className="reflection-box">
          <h4 className="positive-icon">
            <FontAwesomeIcon icon={faThumbsUp} />
            Positive
          </h4>
          <p className="positive">{item?.reflection?.positive ?? "None"}</p>
        </div>

        <div className="reflection-box">
          <h4 className="negative-icon">
            <FontAwesomeIcon icon={faThumbsDown} />
            Negative
          </h4>
          <p className="negative">{item?.reflection?.negative ?? "None"}</p>
        </div>
        <hr />
      </div>
    );
  } else {
    return (
      <div>
        <hr />
        <div>Reflection Pending</div>
        <hr />
      </div>
    );
  }
};

const JournalEntry = ({ item }) => {
  const navigate = useNavigate();
  const handleJournalEdit = () => {
    localStorage.setItem("journalId", item.uuid);
    const journalArray = item.journal;
    const journalEntries = journalArray.slice(0, journalArray.length - 1);
    localStorage.setItem(
      "journal",
      JSON.stringify(
        [
          {
            role: "Assistant",
            content:
              "Hey! I'm here to listen to your thoughts and help you process them. The best part? Everything stays between you and me, and only you have access to your thoughts. Let's work together to make sense of what's on your mind and find clarity",
          },
          ...journalEntries.map((x) => {
            return {
              role: x.role === "user" ? "You" : "Assistant",
              content: x.content,
            };
          }),
        ].reverse()
      )
    );
    const placeHolderLabel = journalArray[journalArray.length - 1];
    localStorage.setItem("placeholder", placeHolderLabel.content);
    navigate("/create");
  };

  return (
    <div>
      {item?.journal ? (
        <div className="journal-entry">
          <h4>Journal Entry</h4>
          {item?.journal &&
            item?.journal.map((i, index) => (
              <p key={index} className="mt-5 content">
                {i.role === "user" ? (
                  <p>{i.content}</p>
                ) : (
                  <small>Prompt: {i.content}</small>
                )}
              </p>
            ))}
          <Button
            variant="outlined"
            className="add-to-journal"
            onClick={handleJournalEdit}
          >
            Edit Journal
          </Button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default JournalCard;
