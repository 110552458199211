import "./style.css";
import React, { useState, useEffect } from "react";
import { DashboardCalenderView } from "../../components/Graph";
import { ApiRequests } from "../../services/api-service";
import {
  getDayOfYear,
  getInputDate,
  getWeekNumber,
} from "../../utils/date.utils";
import JournalCard from "../JournalDetail";

const Analytics = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [summary, setSummaryData] = useState({
    dailySummary: {},
    weeklySummary: {},
    monthlysummary: {},
  });

  const today = new Date();

  useEffect(() => {
    getSummaryData(today, false);
  }, []);

  const getSummaryData = async (selectedDate, retry) => {
    const offsetMinutes = today.getTimezoneOffset();
    await ApiRequests.refreshSummary(getInputDate(selectedDate), offsetMinutes);
    // reset data
    const day = getDayOfYear(selectedDate);
    const year = selectedDate.getFullYear();
    const week = getWeekNumber(selectedDate);
    const month = selectedDate.getMonth() + 1;
    const allData = (await ApiRequests.getAllSummary(day, week, month, year))
      .data;
    setSummaryData(allData);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    console.log(date);
    getSummaryData(date);
  };

  return (
    <div>
      <DashboardCalenderView onDateChange={handleDateChange} />
      <SummaryCard title={"Daily Summary"} item={summary.dailySummary} />
      <SummaryCard title={"Weekly Summary"} item={summary.weeklySummary} />
      <SummaryCard title={"Monthly Summary"} item={summary.monthlysummary} />
    </div>
  );
};

const SummaryCard = ({ title, item }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  return (
    <div>
      {isPopupOpen && (
        <div className="popup" onClick={togglePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={togglePopup}>
              &times;
            </span>
            <JournalCard item={item} />
          </div>
        </div>
      )}
      <div className="card" onClick={togglePopup}>
        <div className="card-header">
          <h3>{title}</h3>
        </div>
        <h4>{item?.reflection?.emoji}</h4>
        <p>{item?.reflection?.summary}</p>
        <div className="icons">
          <div className="icons">
            {item?.reflection?.keywords?.map((icon, index) => (
              <span key={index} className="icon">
                #{icon}{" "}
              </span>
            ))}
          </div>
        </div>
        <p>Journals Saved: {item?.totalJournal}</p>
        <p>AI Consultations: {item?.totalChat ?? 0}</p>
      </div>
    </div>
  );
};

export default Analytics;
