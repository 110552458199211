import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Lottie from "react-lottie";
import { ApiRequests } from "../../services/api-service";

const optionData = [
  {
    value: "mental-health-therapist",
    label: "Mental Health Therapist",
    description:
      "Provides support for mental health issues like stress and anxiety.",
  },
  {
    value: "dietician",
    label: "Dietician",
    description: "Offers personalized dietary and nutrition advice",
  },
  {
    value: "physical-health",
    label: "Physical Health",
    description: "Recommends fitness routines and lifestyle changes",
  },
  {
    value: "general-doctor",
    label: "General Doctor",
    description: "Conducts general health assessments and checkups",
  },
  {
    value: "alternate-medicine",
    label: "Alternate Medicine",
    description: "Suggests alternative medicine practices",
  },
  {
    value: "sexual-health-advisor",
    label: "Sexual Health Advisor",
    description: "Provides guidance on sexual health and well-being",
  },
  {
    value: "lab-assistant",
    label: "Lab Assistant Blood Work",
    description: "Analyzes blood work results and health markers",
  },
  {
    value: "career-counselor",
    label: "Career Counselor",
    description: "Guides on career choices and professional development.",
  },
  {
    value: "interviewer",
    label: "Job Interviewer",
    description: "Guides you for better preparation for peace of mind.",
  },
  {
    value: "your-ai-friend",
    label: "Your AI Friend",
    description: "Offers friendly, non-judgmental conversations",
  },
];

const ChatWindow = () => {
  const [agent, setAgent] = useState(null);
  const [chatId, setChatId] = useState(null);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [sessionEnd, setSessionEnd] = useState(false);
  const chatEndRef = useRef(null);
  const [endMessage, setEndMessage] = useState("");

  const handleSend = async () => {
    if (message.trim()) {
      setMessages([...messages, { text: message, type: "user" }]);
      setMessage("");
      const response = await ApiRequests.continueChat(agent, message);
      setMessages((msgs) => [
        ...msgs,
        { text: response.data.response, type: "bot" },
      ]);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSend();
    }
  };
  const handleChange = (event) => {
    setAgent(event.target.value);
  };

  const handleChatEnd = async () => {
    const response = await ApiRequests.endChat(agent);
    setEndMessage(response.data.response);
    setChatId(null);
    setAgent(null);
  };

  const startChatSession = async () => {
    const response = await ApiRequests.startChat(agent, false);
    setChatId(1);
    setMessage("");
    if (response.data.existing) {
      setMessages(
        response.data.messages.map((x) => {
          return {
            text: x.content,
            type: x.role === "user" ? "user" : "bot",
          };
        })
      );
    } else {
      setMessages([{ text: response.data.response, type: "bot" }]);
    }
  };

  useEffect(() => {
    // Scroll to the bottom of the chat box when messages change
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  if (chatId) {
    return (
      <div className="chat-container">
        <div className="chat-header">
          <h1 className="chat-title">
            {optionData?.find((x) => x.value === agent)?.label ?? ""}
          </h1>
          <p className="chat-description">
            {optionData?.find((x) => x.value === agent)?.description ?? ""}
          </p>
        </div>
        <div className="chat-box">
          {messages.map((msg, index) => (
            <div key={index} className={`message ${msg.type}`}>
              {msg.text}
            </div>
          ))}
          <div ref={chatEndRef} />
        </div>
        <div className="chat-footer">
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleKeyPress}
            placeholder="Type a message..."
          />
          <div className="button-container">
            <button onClick={handleChatEnd}>End Session</button>
            <button onClick={handleSend}>Send</button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <Box className="selectBox">
        <FormControl fullWidth>
          <InputLabel>Select AI Agent</InputLabel>
          <Select
            value={agent}
            label="AI Agent"
            onChange={handleChange}
            className="select"
          >
            {optionData.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <p className="paragraph">
        {optionData?.find((x) => x.value === agent)?.description ?? ""}
      </p>
      <p className="paragraph">
        {endMessage.length
          ? endMessage
          : "We will load the previous session that was not ended by you"}
      </p>
      {/* <div className="divBox">Lottie Animation for Agent</div> */}
      <Button
        variant="outlined"
        className="button"
        onClick={startChatSession}
        disabled={agent === null}
      >
        Start Session
      </Button>
    </div>
  );
};

export { ChatWindow };
